import React from 'react';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import H1 from '~components/typography/H1';
import P from '~components/typography/P';

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="404 - Ups Fehler - hier arbeiten wir wohl gerade dran!"
      desc="404 ist eine Fehlermeldung, die zeigt, dass eine Seite nicht verfügbar ist. Wahrscheinlich verändern wir gerade etwas an der Homepage. Schau später vorbei!"
    >
      <div className="bg-gray-50 py-8">
        <Container noMargin>
          <div className="mx-auto space-y-6 text-center">
            <H1>Seite nicht gefunden</H1>
            <P>Bitte benutze das Hauptmenü.</P>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
